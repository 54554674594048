<script>

export default {
  name: 'ViewChallenges',

  data() {
    return {
      loading: false
    }
  },

  computed: {
    challenges() {
      return this.$challenges.challenges?.list ?? []
    },

    hasChallenges() {
      return !!this.challenges.length
    }
  },

  methods: {
    brandLogo(logo) {
      return logo?.url?.all
    },

    async init() {
      try {
        this.loading = true

        const brands = await this.$brands.getBrands()
        await this.$challenges.getChallenges()

        this.$challenges.challenges.list.forEach(c => {
          c.brand = brands.find(b => b.projectId === c.projectId)
        })
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    logoUrl(logo) {
      return logo?.url
    },

    stripText(text) {
      return text.replace(/<\/?[^>]+(>|$)/g, '')
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.init()
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_dashboard-dashboard' }"
    class="module-challenges challenges"
    :loading="loading"
    :subtitle="$t('conn3ct-wallet.challenges_list_subtitle')"
    :title="$t('conn3ct-wallet.challenges_list_title')"
  >
    <layout-entity-list
      class="challenges__list"
      :empty="!hasChallenges"
      :empty-description="$t('conn3ct-wallet.wallet_no_challenges_description')"
      :empty-title="$t('conn3ct-wallet.wallet_no_challenges_title')"
    >
      <ui-card
        v-for="challenge in challenges"
        class="challenges__challenge"
        illustration-format="banner"
        :key="challenge.id"
        :title="challenge.name"
        :to="{ name: 'sayl-connect_challenges-challenge', params: { id: challenge.id } }"
      >
        <template v-slot:illustration>
          <ui-illustration
            :alt="challenge.name"
            :src="logoUrl(challenge.logo)"
          />
        </template>

        <template
          v-if="challenge.descrMini"
          v-slot:subtitle
        >
          <div
            v-line-clamp="2"
            v-html="stripText(challenge.descrMini)"
            class="challenges__challenge-description"
          ></div>
        </template>

        <template v-slot:footer>
          <div
            v-if="$basil.get(challenge, 'brand.name')"
            class="challenges__challenge-brand"
          >
            <ui-illustration
              v-if="$basil.get(challenge, 'brand.logo')"
              :alt="$basil.get(challenge, 'brand.name')"
              class="challenges__challenge-brand-logo"
              :src="brandLogo(challenge.brand.logo)"
            />

            <span class="ui-card__subtitle">{{ $basil.get(challenge, 'brand.name') }}</span>
          </div>

          <button class="challenges__challenge-cta">
            {{ $t('conn3ct-wallet.view') }}
          </button>
        </template>
      </ui-card>
    </layout-entity-list>
  </layout-page>
</template>
